<template>
	<div class="main-content">
		<div class="header bg-white border-bottom">
			<div class="container-fluid">
				<div class="header-body border-0">
					<div class="row align-items-end">
						<div class="col">
							<h6 class="header-pretitle">Overview</h6>
							<h1 class="header-title">Edit Company</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<span @click="$router.go(-1)" class="btn btn-light mb-4 btn-sm">
				Go back
			</span>
			<div v-if="loading" class="d-flex justify-content-center align-items-center">
				<div class="spinner-border" role="status"></div>
			</div>
			<div class="row" v-else>
				<div class="col-12 col-xl-8">
					<div class="card">
						<div class="card-header">
							<div>
								<h4 class="card-header-title">
									{{ companyId ? 'Edit' : 'Add a New' }} Company
								</h4>
							</div>
						</div>
						<div class="card-body">
							<div class="alert alert-danger alert-dismissible fade show" role="alert" v-if="errorMessage">
								{{ errorMessage }}
							</div>
							<form @submit.prevent="manageCompany">
								<h4>Company Information</h4>
								<small class="text-muted">Information about the organization. </small>
								<hr class="my-4" />
								<div class="row">
									<div class="col-12">
										<div class="form-group">
											<label class="form-label"> Company name </label>
											<input v-model.trim="company.corporate_name" required type="text" class="form-control" />
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label class="form-label"> Official Phone Number</label>
											<input v-model.trim="company.corporate_phone" required type="text" class="form-control mb-3"
												placeholder="(___)___-____" inputmode="text" />
										</div>
									</div>
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label class="form-label">
												Official E-mail Address
											</label>
											<input v-model.trim="company.email" required type="email" class="form-control" />
										</div>
									</div>
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label class="form-label"> Sector </label>
											<select class="form-select form-control mb-3" v-model.trim="company.industry">
												<option value="Government">Government</option>
												<option value="Telecommunication">
													Telecommunication
												</option>
												<option value="Legal services">Legal services</option>
												<option value="Construction">Construction</option>
												<option value="Food processing">Food processing</option>
												<option value="Consulting">Consulting</option>
												<option value="Gambling">Gambling</option>
												<option value="Retail sales">Retail sales</option>
												<option value="Franchising">Franchising</option>
												<option value="Real estate">Real estate</option>
												<option value="Education">Education</option>
												<option value="Pharmaceuticals">Pharmaceuticals</option>
												<option value="Hospitality/Tourism">
													Hospitality/Tourism
												</option>
												<option value="Mass media">Mass media</option>
												<option value="Healthcare/hospitals">
													Healthcare/hospitals
												</option>
												<option value="Public health">Public health</option>
												<option value="Information technology">
													Information technology
												</option>
												<option value="Waste disposal">Waste disposal</option>
												<option value="Banking">Banking</option>
												<option value="Insurance">Insurance</option>
												<option value="Financial services">
													Financial services
												</option>
												<option value="FMCG">FMCG</option>
											</select>
										</div>
									</div>
									<div class="col-12">
										<div class="form-group">
											<label class="form-label"> Official Address </label>
											<textarea v-model.trim="company.corporate_address" required class="form-control"
												rows="2"></textarea>
										</div>
									</div>
								</div>
								<hr class="my-4" />
								<h4>Admin Information</h4>
								<small class="text-muted">Information about the key personnel that will manage the
									company account.</small>
								<div class="row mt-4">
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label class="form-label"> First name </label>
											<input v-model.trim="company.fname" required type="text" class="form-control" />
										</div>
									</div>
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label class="form-label"> Last name </label>
											<input v-model.trim="company.lname" required type="text" class="form-control" />
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label class="mb-1"> Email address </label>
											<input v-model.trim="company.person_email" required type="email" class="form-control" />
										</div>
									</div>
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label class="form-label"> Phone </label>
											<input v-model.trim="company.person_phone" required type="text" class="form-control mb-3"
												placeholder="(___)___-____" inputmode="text" />
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label class="mb-1"> Password </label>
											<input v-model.trim="company.password" :required="!companyId" type="password"
												class="form-control" />
										</div>
									</div>
								</div>
								<hr class="my-4" />
								<h4>Other Settings</h4>
								<div class="row mt-4">
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label class="form-label">
												Check-in/Check-out option</label>
											<select class="form-select form-control mb-3" v-model.trim="company.cico_option">
												<option value="manual">Manual</option>
												<option value="automatic">Automatic</option>
											</select>
										</div>
									</div>
								</div>
								<button type="submit" class="btn btn-primary mt-4" :disabled="processing">
									{{
										processing
											? 'Processing...'
											: `${companyId ? 'Update' : 'Add'} Company`
									}}
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import Swal from 'sweetalert2'
import { axiosInstance } from '@/plugins/axios'
import { ref, inject, defineProps } from 'vue'
import { useRouter } from 'vue-router/composables'

const router = useRouter()

const props = defineProps({
  companyId: {
    type: String | Number
  }
})

// const swal = inject('$swal')
const company = ref({
  fname: '',
  lname: '',
  person_email: '',
  person_phone: '',
  email: '',
  password: '',
  corporate_name: '',
  corporate_phone: '',
  corporate_address: '',
  industry: '',
  cico_option: ''
})
const processing = ref(false)
const errorProcessing = ref(false)
const errorMessage = ref('')
const loading = ref(false)
const errorLoading = ref(false)




const fetchCompany = () => {
  loading.value = true
  errorLoading.value = false

  axiosInstance
    .get(`/v1/corporates/${props.companyId}`)
    .then((res) => {
      company.value = { ...res.data }
    })
    .catch(() => {
      errorLoading.value = true
    })
    .finally(() => (loading.value = false))
}

const manageCompany = () => {
  processing.value = true
  errorProcessing.value = false

  errorMessage.value = ''

  if (!company.value.avatar) {
    delete company.value.avatar
  }

  if (!company.value.city_id) {
    delete company.value.city_id
  }

  // Removing certain fields based on state of api
  const payload = company.value
  const fieldsToRemove = [
    'restrict_booking_on_public_routes',
    'restrict_booking_on_public_routes_with_corporate_wallet',
    'restrict_booking_on_private_routes_with_personal_wallet',
    'restrict_booking_on_private_routes_with_corporate_wallet',
    'restrict_booking_with_corporate_wallet_from_staff',
    'restrict_staff_booking_on_private_route_with_corp_wallet',
    'restrict_staff_booking_on_public_route_with_corp_wallet',
    'restrict_admin_bookings_cancellation',
    'verification_password'
  ]
  fieldsToRemove.forEach((field) => {
    if (payload && Object.prototype.hasOwnProperty.call(payload, field)) {
      delete payload[field]
    }
  })

  axiosInstance({
    method: props.companyId ? 'PATCH' : 'POST',
    url: props.companyId
      ? `/v1/corporates/${props.companyId}`
      : '/v1/corporates/',
    data: {
      ...payload
    }
  })
    .then((res) => {
      Swal.fire({
        icon: 'success',
        title: `Company ${props.companyId ? 'Updated' : 'Added'}`,
        text: `The company "${company.value.corporate_name}" has been ${props.companyId ? 'updated' : 'added'
          } succesfully`,
        showCloseButton: true
      }).then(() => {
        router.push({
          name: 'ShowCompanyInfo',
          params: {
            companyId: res?.data?.id ?? props.companyId,
            status: res.data.active === 0 ? 'inactive' : 'active'
          }
        })
      })
    })
    .catch((e) => {
      errorProcessing.value = true

      let msg = e.toString()
      if (e && e.response && e.response.data && e.response.data.message) {
        msg = e.response.data.message
      }

      errorMessage.value = msg

      Swal.fire({
        icon: 'error',
        text: 'An error occured, please try again',
        showCloseButton: true
      })
    })
    .finally(() => (processing.value = false))
}

if (props.companyId) {
  fetchCompany()
}
</script>
